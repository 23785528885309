export default {
  data: function data() {
    return {};
  },
  methods: {
    here: function here() {
      if (sessionStorage.getItem('nof_userInfo')) {
        this.$router.push('/index');
      } else {
        this.$router.push('/');
      }
    }
  }
};